import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Avatar, LoremIpsum } from "react-lorem-ipsum";
import WideContainer from "rxs/components/WideContainer";
import { useTranslation } from "react-i18next";

import video from "videos/Services_01.mp4";

import services1 from "images/services/Services01.jpg";
import services2 from "images/services/Services02.jpg";

import pService1 from "images/services/PrograServices_01.jpg";
import pService2 from "images/services/PrograServices_02.jpg";
import pService3 from "images/services/PrograServices_03.jpg";

import aService1 from "images/services/ArtServices_01.jpg";
import aService2 from "images/services/ArtServices_02.jpg";
import aService3 from "images/services/ArtServices_03.jpg";

import useMobile from "rxs/hooks/useMobile";

const Services = () => {
  const { t } = useTranslation();
  const mobile = useMobile(500);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="p-relative cl-white f-center ta-center pt-224 pb-96 header">
        <video
          className="p-absolute mnw-100 mnh-100 zi-0 t-0"
          loop
          muted
          autoPlay
          src={video}
          type="video/mp4"
        />

        <div className="logo aone" style={{ marginTop: -65 }} />
        <div className="tt-uppercase fs-64 fw-bold">{t("servicesTitle")}</div>
      </div>
      <WideContainer className="py-96 px-32 ta-justify" maxWidth="800px">
        {t("servicesText")}
      </WideContainer>
      <div className={mobile ? "f-col" : "f-row"}>
        <img
          className={`p-4 ${mobile ? "w-100" : "w-50"}`}
          src={services1}
          alt="services"
        />
        <img
          className={`p-4 ${mobile ? "w-100" : "w-50"}`}
          src={services2}
          alt="services"
        />
      </div>
      <WideContainer maxWidth="1100px">
        <div className="fs-40 fw-bold mt-48 mx-16">
          {t("programingServicesTitle")}
        </div>
        <Service
          image={pService1}
          title={t("programingServices1Title")}
          text={t("programingServices1Text")}
        />
        <Service
          image={pService2}
          title={t("programingServices2Title")}
          text={t("programingServices2Text")}
        />
        <Service
          image={pService3}
          title={t("programingServices3Title")}
          text={t("programingServices3Text")}
        />
        <div className="fs-40 fw-bold mt-48 mx-16">{t("artServicesTitle")}</div>
        <Service
          image={aService1}
          title={t("artServices1Title")}
          text={t("artServices1Text")}
        />
        <Service
          image={aService2}
          title={t("artServices2Title")}
          text={t("artServices2Text")}
        />
        <Service
          image={aService3}
          title={t("artServices3Title")}
          text={t("artServices3Text")}
        />
      </WideContainer>
    </>
  );
};

const Service = ({ image, title, text }) => {
  const mobile = useMobile(700);
  return (
    <div className={`mt-32 ${mobile ? "col-m16" : "f-row"}`}>
      <img
        src={image}
        alt="service"
        width={mobile ? "100%" : 350}
        // height={175}
        className="of-cover"
      />
      <div className="px-16">
        <div className="fs-28 fw-bold">{title}</div>
        <div className="ta-justify">{text}</div>
      </div>
    </div>
  );
};

export default Services;
