import React from "react";
import WideContainer from "./WideContainer";

import "./Footer.css";

const Footer = ({ className = "", children, ...props }) => {
  return (
    <WideContainer className={`footer ${className}`} {...props}>
      {children}
    </WideContainer>
  );
};

export default Footer;
