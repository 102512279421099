import React from "react";

const WideContainer = React.forwardRef(
  (
    {
      className = "",
      containerClassName = "",
      containerStyle = {},
      maxWidth = "1280px",
      children,
      ...props
    },
    ref
  ) => {
    return (
      <div ref={ref} className={`ai-center ${className}`} {...props}>
        <div
          className={`w-100 ${containerClassName}`}
          style={{ maxWidth, ...containerStyle }}
        >
          {children}
        </div>
      </div>
    );
  }
);

export default WideContainer;
