import { forwardRef, useRef } from "react";
import WideContainer from "rxs/components/WideContainer";
import useMobile from "rxs/hooks/useMobile";
import useVisible from "rxs/hooks/useVisible";

const Banner = forwardRef(
  (
    {
      TextComponent,
      ImageComponent,
      textContainerClassName = "",
      right = false,
      noTextTransition,
      noImageTransition,
    },
    ref
  ) => {
    const mobile = useMobile();

    const _ref = useRef();
    const [background] = useVisible({
      once: true,
      threshold: 0.1,
      ref: ref || _ref,
    });

    const [text] = useVisible({
      once: true,
      threshold: 0.5,
      ref: ref || _ref,
    });

    const [image] = useVisible({
      once: true,
      threshold: mobile ? 0.7 : 0.9,
      ref: ref || _ref,
    });

    return (
      <WideContainer
        maxWidth="1200px"
        className="p-relative py-16"
        containerClassName={
          mobile ? "f-col-reverse" : right ? "f-row-reverse" : "f-row"
        }
        ref={ref || _ref}
      >
        <div
          className="p-absolute bg-dark-gray h-100"
          style={{
            left: right && !mobile ? "unset" : 0,
            right: right && !mobile ? 0 : "unset",
            top: 0,
            transition: "all 0.5s",
            width: background ? "100%" : "0%",
            zIndex: -1,
          }}
        />
        <div
          className={`${mobile ? "" : "w-50"} mx-32 ${textContainerClassName}`}
          style={
            noTextTransition
              ? {}
              : { opacity: text ? 1 : 0, transition: "all 1s" }
          }
        >
          {TextComponent}
        </div>
        <div
          className={`${mobile ? "" : "w-50"} p-16 relative f-center`}
          style={
            noImageTransition
              ? {}
              : {
                  clipPath: `polygon(
          ${mobile ? 0 : +(!image && right) * 100}% ${
                    mobile ? +!image * 100 : 0
                  }%,
          ${mobile ? 100 : +(image || right) * 100}% ${
                    mobile ? +!image * 100 : 0
                  }%,
          ${mobile ? 100 : +(image || right) * 100}% 100%,
          ${mobile ? 0 : +(!image && right) * 100}% 100%
        )`,
                  transition: "all 1s",
                }
          }
        >
          {ImageComponent}
        </div>
      </WideContainer>
    );
  }
);

export default Banner;
