import React, { useEffect } from "react";
import WideContainer from "rxs/components/WideContainer";

import video from "videos/Nemo_01.mp4";
import ue from "images/ue-logo.png";
import { useTranslation } from "react-i18next";
import useMobile from "rxs/hooks/useMobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import ggpo from "images/nemo/ggpo-min.png";
import epic from "images/nemo/epic-min.png";

import { ReactComponent as Online } from "images/icons/online.svg";
import { ReactComponent as Services } from "images/icons/services.svg";
import { ReactComponent as Fighting } from "images/icons/figthing.svg";
import { ReactComponent as Combat } from "images/icons/combat.svg";
import { ReactComponent as Cogs } from "images/icons/cogs.svg";
import { ReactComponent as Fps } from "images/icons/fps.svg";
import { ReactComponent as Projectile } from "images/icons/projectile.svg";
import engines from "images/engines-min.png";
import NemoRibbon from "aone/components/NemoRibbon";

const Nemo = () => {
  const { t } = useTranslation();
  const mobile = useMobile();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="p-relative cl-white f-center ta-center pt-224 pb-96 header">
        <video
          className="p-absolute mnw-100 mnh-100 zi-0 t-0"
          loop
          muted
          autoPlay
          src={video}
          type="video/mp4"
        />
        <div className="logo nemo" style={{ marginTop: -65 }} />
      </div>
      <WideContainer className="py-96 px-32 ta-justify" maxWidth="800px">
        <div className={mobile ? "col-m32-r" : "row-m32 ai-center"}>
          <div className="f-1">{t("nemoText")}</div>
          <img
            className="ai-start"
            src={ue}
            alt="Unreal Engine"
            height={mobile ? "" : 120}
          />
        </div>
      </WideContainer>
      <WideContainer maxWidth="950px" className="bg-dark-gray py-16">
        <div className="mx-32 col-m16">
          <div className="fw-bold fs-24" style={{ marginLeft: 40 }}>
            {t("benefits")}
          </div>
          <div className="row-m16 ai-center">
            <FontAwesomeIcon
              className={`as-center fs-24 c-pointer`}
              icon={solid("diamond")}
            />
            <div>{t("benefit1")}</div>
          </div>
          <div className="row-m16 ai-center">
            <FontAwesomeIcon
              className={`as-center fs-24 c-pointer`}
              icon={solid("diamond")}
            />
            <div>{t("benefit2")}</div>
          </div>
          <div className="row-m16 ai-center">
            <FontAwesomeIcon
              className={`as-center fs-24 c-pointer`}
              icon={solid("diamond")}
            />
            <div>{t("benefit3")}</div>
          </div>
        </div>
        <div className="row-32 f-center">
          <img
            className="mnw-0 of-contain"
            src={ggpo}
            alt="ggpo"
            height={150}
          />
          <img
            className="mnw-0 of-contain"
            src={epic}
            alt="epic"
            height={100}
          />
        </div>
      </WideContainer>
      <NemoRibbon
        icon={Fps}
        title={t("framePerfectTitle")}
        text={t("framePerfectText")}
      />
      <NemoRibbon
        icon={Online}
        title={t("smoothOnlineExperienceTitle")}
        text={t("smoothOnlineExperienceText")}
        right
      />
      <NemoRibbon
        icon={Services}
        title={t("sameFightingExperienceTitle")}
        text={t("sameFightingExperienceText")}
      />
      <NemoRibbon
        image={engines}
        title={t("multiDeploymentTitle")}
        text={t("multiDeploymentText")}
        right
      />
      <NemoRibbon
        icon={Fighting}
        title={t("fightingFormatTitle")}
        text={t("fightingFormatText")}
      />
      <NemoRibbon
        icon={Combat}
        title={t("combatStylesTitle")}
        text={t("combatStylesText")}
        right
      />
      <NemoRibbon
        icon={Cogs}
        title={t("combatScriptingConfigurationTitle")}
        text={t("combatScriptingConfigurationText")}
      />
      <NemoRibbon
        icon={Projectile}
        title={t("projectileTypesTitle")}
        text={t("projectileTypesText")}
        right
      />
    </>
  );
};

export default Nemo;
