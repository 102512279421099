import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Contact from "screens/Contact";
import Home from "./screens/Home";
import Main from "./screens/Main";
import Nemo from "./screens/Nemo";
import News from "./screens/news/News";
import Services from "./screens/Services";

import "./App.css";
import Clients from "screens/Clients";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route index element={<Home />} />
          <Route path="omen" element={<Services />} />
          <Route path="nemo" element={<Nemo />} />
          <Route path="services" element={<Services />} />
          <Route path="clients" element={<Clients />} />
          <Route path="news" element={<News />} />
          <Route path="contact" element={<Contact />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
