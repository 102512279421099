import React from "react";
import { Outlet } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid,
  regular,
  brands,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import NavBar from "aone/components/NavBar";
import { useTranslation } from "react-i18next";
import Footer from "aone/components/Footer";

const Main = () => {
  const { i18n, t } = useTranslation();
  return (
    <>
      <NavBar
        routes={[
          { route: "nemo", name: t("nemo") },
          { route: "services", name: t("services") },
          { route: "clients", name: t("clients") },
          {
            route: "news",
            name: t("news"),
            rightComponent: <div className="notification">1</div>,
          },
          { route: "contact", name: t("contact") },
        ]}
      />
      <div className="f-1">
        <Outlet />
      </div>

      <Footer
        className="bg-black cl-white"
        siteMap={[
          { route: "nemo", name: t("nemo") },
          { route: "services", name: t("services") },
          { route: "clients", name: t("clients") },
          { route: "news", name: t("news") },
          { route: "contact", name: t("contact") },
        ]}
        games={[{ route: "https://omenofsorrow.com/", name: "Omen of Sorrow" }]}
      />
      <div
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          display: "block",
          zIndex: 99999,
          transform: "scale(1.5)",
          transformOrigin: "bottom left",
        }}
      >
        <button style={{ width: 30 }} onClick={() => i18n.changeLanguage("en")}>
          en
        </button>
        <button style={{ width: 30 }} onClick={() => i18n.changeLanguage("jp")}>
          jp
        </button>
      </div>
    </>
  );
};

export default Main;
