import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import WideContainer from "rxs/components/WideContainer";
import useMobile from "rxs/hooks/useMobile";

import "./NavBar.css";

const NavBar = React.forwardRef(
  (
    {
      logo = true,
      routes = [
        /*{route, name, className?}*/
      ],
      mobileWidth = 760,
      containerClassName = "",
      className = "",
      routeClassName = "",
      ...props
    },
    ref
  ) => {
    const [menu, setMenu] = useState(false);

    const mobile = useMobile(mobileWidth);

    useEffect(() => {
      setMenu(false);
    }, [mobile]);

    const toggleMenu = () => {
      setMenu(p => !p);
    };
    return (
      <>
        <WideContainer
          ref={ref}
          className={`navbar ${mobile ? "mobile" : ""} ${
            mobile && menu ? "menu" : ""
          }  ${containerClassName}`}
          {...props}
        >
          <div
            className={`w-100 p-32 jc-center ${
              !mobile ? "f-row" : ""
            } ${className}`}
          >
            <div
              className={`f-row jc-end logo-container ${
                logo || mobile ? "f-1" : ""
              }`}
            >
              {logo && (
                <div className="f-1 jc-center ai-start w-100">
                  <Link
                    to=""
                    onClick={() => {
                      setMenu(false);
                      window.scrollTo(0, 0);
                    }}
                  >
                    <div className="logo" />
                  </Link>
                </div>
              )}
              {mobile && (
                <FontAwesomeIcon
                  className={`as-center fs-32 c-pointer`}
                  style={{ width: "32px" }}
                  icon={menu ? solid("x") : solid("bars")}
                  onClick={toggleMenu}
                />
              )}
            </div>
            {(!mobile || menu) && (
              <>
                {routes.map((v, i) => (
                  <React.Fragment key={i}>
                    {!mobile && i !== 0 && (
                      <div
                        className={"w-100 " + v.route + "-separator"}
                        style={{ minWidth: "16px", maxWidth: "64px" }}
                      />
                    )}
                    <Link
                      to={v.route}
                      className={`fw-bold c-pointer noselect as-center ${
                        mobile ? "w-100 ta-center p-16" : ""
                      } ${routeClassName} ${v.className}`}
                      onClick={() => {
                        setMenu(false);
                        window.scrollTo(0, 0);
                      }}
                    >
                      <div
                        className={`jc-center ${
                          v.rightComponent ? "f-row" : ""
                        }`}
                      >
                        {v.name}
                        {v.rightComponent && (
                          <div className="as-center ml-4">
                            {v.rightComponent}
                          </div>
                        )}
                      </div>
                    </Link>
                  </React.Fragment>
                ))}
              </>
            )}
          </div>
        </WideContainer>
      </>
    );
  }
);

export default NavBar;
