import { getGamingMediaName, getRandomPastDate } from "rxs/placeholders";
import { formatDate } from "rxs/utils";
import "./Card.css";

const Card = ({ news }) => {
  const { header, journalist, url, date, background } = news;
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <div className="c-pointer card cl-white mnh-100">
        <div
          className="body p-64 pt-128 f-1"
          style={{
            backgroundImage: `url(${background})`,
          }}
        >
          {/* <div className="bg"></div> */}
          <span>
            <span className="fw-bold ">{journalist}</span>{" "}
            <span className="fs-12">- {date}</span>
          </span>
          <div className="fs-24 pt-16">{header}</div>
        </div>
      </div>
    </a>
  );
};

export default Card;
