import { forwardRef, useRef } from "react";
import useMobile from "rxs/hooks/useMobile";
import Ribbon from "./Ribbon";

const NemoRibbon = ({
  icon: Icon,
  image,
  title = "",
  text = "",
  right = false,
  children,
  ...props
}) => {
  const mobile = useMobile();

  return (
    <Ribbon className="mt-192" right={right}>
      <div
        className={`m-32 ${
          mobile ? "col-m16-r" : right && !mobile ? "row-m32-r" : "row-m32"
        }`}
      >
        <div
          className={`f-1 jc-center ${
            right && !mobile ? " ta-right tal-right" : "ta-left tal-left"
          }`}
        >
          <div className="fw-bold fs-32"> {title}</div>
          <div className="ta-justify"> {text}</div>
        </div>
        {image ? (
          <img
            className="as-center"
            src={image}
            alt=""
            height={mobile ? 100 : 200}
          />
        ) : (
          <Icon
            className="cl-white mnw-0 as-center"
            height={mobile ? 100 : 200}
            width={mobile ? 100 : 200}
          />
        )}
      </div>
    </Ribbon>
  );
};

export default NemoRibbon;
