import "./Button.css";

const Button = ({ className = "", as = "div", children, ...props }) => {
  const Wrapper = as;
  return (
    <Wrapper
      className={`btn p-16 bg-accent cl-white as-center tt-uppercase ${className}`}
      {...props}
    >
      {children}
    </Wrapper>
  );
};

export default Button;
