import { forwardRef, useRef } from "react";
import WideContainer from "rxs/components/WideContainer";
import useMobile from "rxs/hooks/useMobile";
import useVisible from "rxs/hooks/useVisible";

import "./Ribbon.css";

const Ribbon = forwardRef(
  ({ right = false, className = "", children, ...props }, ref) => {
    const mobile = useMobile();

    const _ref = useRef();
    const [background] = useVisible({
      once: true,
      threshold: 0.1,
      ref: ref || _ref,
    });

    return (
      <WideContainer
        maxWidth="1200px"
        className={`p-relative py-16 ${className}`}
        ref={ref || _ref}
        {...props}
      >
        <div
          className="p-absolute bg-dark-gray h-100"
          style={{
            left: right && !mobile ? "unset" : 0,
            right: right && !mobile ? 0 : "unset",
            top: 0,
            transition: "all 0.5s",
            width: background ? "100%" : "0%",
            zIndex: -1,
          }}
        />
        <div className={background ? "fade-1" : "o-0"}>{children}</div>
      </WideContainer>
    );
  }
);

export default Ribbon;
