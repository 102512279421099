import { useEffect, useRef, useState } from "react";

const useVisible = ({
  func = () => {},
  once = false,
  threshold = 1,
  ref,
  ...options
}) => {
  let _ref = useRef();

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const node = ref?.current || _ref.current;
    if (!node) {
      return;
    }

    const callback = (entries, observer) => {
      const entry = entries[0];
      if (entry.isIntersecting && (!once || !visible)) {
        setVisible(true);
        func();
      }
    };

    const observer = new IntersectionObserver(callback, {
      threshold,
      ...options,
    });
    observer.observe(node);

    return () => {
      observer.unobserve(node);
    };
  }, [options]);

  return [visible, _ref];
};

export default useVisible;
