import React, { useEffect, useState } from "react";
import RXSNavBar from "rxs/components/NavBar";
import useMobile from "rxs/hooks/useMobile";
import useScrollPosition from "rxs/hooks/useScrollPosition";

import "./NavBar.css";

const NavBar = ({ ...props }) => {
  const scrollPosition = useScrollPosition();
  const [onTop, setOnTop] = useState(true);
  const mobile = useMobile();
  const tablet = useMobile(900);

  useEffect(() => {
    if (scrollPosition === 0) {
      if (!onTop) {
        setOnTop(true);
      }
    } else {
      if (onTop) {
        setOnTop(false);
      }
    }
  }, [scrollPosition, onTop]);

  return (
    <RXSNavBar
      containerClassName={`bg-black cl-white navbar ${
        mobile
          ? "transparent p-absolute"
          : `p-fixed ${onTop ? `transparent ${!tablet ? "padding" : ""}` : ""}`
      }`}
      className="p-24"
      routeClassName="hover-accent tt-uppercase"
      {...props}
    />
  );
};

export default NavBar;
