import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Avatar, LoremIpsum } from "react-lorem-ipsum";
import WideContainer from "rxs/components/WideContainer";
import { useTranslation } from "react-i18next";

import logoClient1 from "images/clients/FunLabsLogo_x4.jpg";
import image1Client1 from "images/clients/OpenCountry.jpg";
import image2Client1 from "images/clients/NerfLegends.jpg";
import logoClient2 from "images/clients/SunspearLogo.jpg";
import image1Client2 from "images/clients/Immortal_01.jpg";
import image2Client2 from "images/clients/Immortal_02.jpg";
import logoClient3 from "images/clients/CocaCola_Logo.jpg";
import image1Client3 from "images/clients/CocaCola_01.jpg";
import image2Client3 from "images/clients/CocaCola_02.jpg";

import useMobile from "rxs/hooks/useMobile";

const Clients = () => {
  const { t } = useTranslation();
  const mobile = useMobile();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <WideContainer maxWidth="1100px" className={mobile ? "pt-96" : "pt-224"}>
        <div className={`fs-40 fw-bold tt-capitalize ${mobile ? "p-16" : ""}`}>
          {t("clients")}
        </div>
        <Client
          logo={logoClient1}
          title={t("client1Title")}
          subtitle={t("client1Subtitle")}
          text={t("client1Text")}
          image1={image1Client1}
          image2={image2Client1}
        />
        <Client
          logo={logoClient2}
          title={t("client2Title")}
          subtitle={t("client2Subtitle")}
          text={t("client2Text")}
          image1={image1Client2}
          image2={image2Client2}
        />
        <Client
          logo={logoClient3}
          title={t("client3Title")}
          subtitle={t("client3Subtitle")}
          text={t("client3Text")}
          image1={image1Client3}
          image2={image2Client3}
        />
      </WideContainer>
    </>
  );
};

const Client = ({ logo, title, subtitle, text, image1, image2 }) => {
  const mobile = useMobile(600);
  return (
    <div className="mt-72 mb-32">
      <div className={mobile ? "f-col" : "f-row"}>
        <img src={logo} alt="service" width={200} className="as-center" />
        <div className="p-16">
          <div className="fs-28 fw-bold">{title}</div>
          <div className="ta-justify">{subtitle}</div>
          <div className="ta-justify pt-16">{text}</div>
        </div>
      </div>
      <div className={`mt-32 ${mobile ? "f-col" : "f-row"}`}>
        <img src={image1} alt="" className={mobile ? "w-100" : "w-50"} />
        <img src={image2} alt="" className={mobile ? "w-100" : "w-50"} />
      </div>
    </div>
  );
};

export default Clients;
