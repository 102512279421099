import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import RXSFooter from "rxs/components/Footer";
import useMobile from "rxs/hooks/useMobile";
import "./Footer.css";

const Footer = ({ siteMap = [], games = [], ...props }) => {
  const { t } = useTranslation();
  const mobile = useMobile();
  return (
    <RXSFooter {...props}>
      <div className={`p-32 ${mobile ? "py-64" : "py-128"} `}>
        <div
          className={mobile ? "col-m8 as-center" : " jc-space-between row-m16"}
        >
          <div style={{ order: +mobile || 0 }}>
            <Link to="" onClick={() => window.scrollTo(0, 0)}>
              <div className={`logo mr-0 ${mobile ? "mb-16" : "mb-32"} `} />
            </Link>

            <div className="my-8">© AOne Games 2022</div>
            <div className="my-8 tt-capitalize">{t("copyright")}</div>
          </div>
          <Section title={t("siteMap")}>
            {siteMap.map((v, i) => (
              <Link
                key={i}
                to={v.route}
                className={`noselect tt-capitalize`}
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                {v.name}
              </Link>
            ))}
          </Section>

          <Section title={t("ourGames")}>
            {games.map((v, i) => (
              <a
                key={i}
                href={v.route}
                target="_blank"
                rel="noreferrer"
                className="noselect"
              >
                {v.name}
              </a>
            ))}
          </Section>
          <Section title={t("contact")}>
            <div className="fw-bold tt-uppercase fs-18">{t("support")}</div>
            <a
              className={mobile ? "mb-8" : "mb-16"}
              href={`mailto:${t("supportEmail")}`}
            >
              {t("supportEmail")}
            </a>
            <div className="fw-bold tt-uppercase fs-18">{t("press")}</div>
            <a
              className={mobile ? "mb-8" : "mb-16"}
              href={`mailto:${t("pressEmail")}`}
            >
              {t("pressEmail")}
            </a>
            <div className="fw-bold tt-uppercase fs-18">{t("business")}</div>
            <a
              className={mobile ? "mb-8" : "mb-16"}
              href={`mailto:${t("businessEmail")}`}
            >
              {t("businessEmail")}
            </a>
          </Section>
          {/* <Section title={t("legal")}>
            <div className="col-m16">
              <div>Terms of Service</div>
              <div>Privacy Policy</div>
            </div>
          </Section> */}
        </div>
      </div>
    </RXSFooter>
  );
};

const Section = ({ title = "Title", children }) => {
  const mobile = useMobile();
  const [visible, setVisible] = useState(false);
  return (
    <div>
      <div
        className={`fw-bold tt-uppercase fs-24 noselect ${
          mobile ? "c-pointer mb-16" : "mb-48"
        }`}
        onClick={() => (mobile ? setVisible(p => !p) : {})}
      >
        <div className="row-m16 jc-space-between">
          <div>{title}</div>
          {mobile ? (
            <FontAwesomeIcon
              className={`as-center fs-24 c-pointer v-flip ${
                visible ? "active" : ""
              }`}
              icon={solid("chevron-down")}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      {!mobile || visible ? (
        <div className={mobile ? "col-m8 mb-16" : "col-m16"}>{children}</div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Footer;
