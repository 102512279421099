import React, { useEffect } from "react";
import WideContainer from "rxs/components/WideContainer";
import { useTranslation } from "react-i18next";

import map from "images/contact/Map_Points-min.png";
import marker from "images/contact/Map_Points_Red-min.png";

import "./Contact.css";
import useMobile from "rxs/hooks/useMobile";

const Contact = () => {
  const { t } = useTranslation();
  const mobile = useMobile();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <WideContainer className="cl-white f-center ta-center pt-224 pb-96 header contact">
        <div className="fs-64 fw-bold px-16 tt-capitalize ">{t("contact")}</div>
        <div className="fs-24 p-32">{t("contactSubtitle")}</div>
      </WideContainer>
      <WideContainer
        className="p-32 pt-128"
        containerClassName={`${mobile ? "" : "f-row"} f-center g-32`}
      >
        <div
          className="p-relative"
          style={{ width: mobile ? "100%" : "calc(50% - 16px)" }}
        >
          <img
            className="ai-start"
            src={map}
            alt="locations"
            draggable={false}
          />
          <img
            className="marker"
            src={marker}
            alt="tokyo"
            style={{
              top: "31.4126394052%",
              left: "19.2408376963%",
            }}
            draggable={false}
          />
          <img
            className="marker"
            src={marker}
            alt="miami"
            style={{
              top: "37.5464684015%",
              left: "64.1361256545%",
            }}
            draggable={false}
          />
          <img
            className="marker"
            src={marker}
            alt="santiago"
            style={{
              top: "83.4572490706%",
              left: "70.0261780105%",
            }}
            draggable={false}
          />
        </div>
        <div
          className="ta-center f-center py-32"
          style={{ width: mobile ? "100%" : "calc(30% - 16px)" }}
        >
          <div className="fs-32 fw-bold mb-16">{t("contactText")}</div>

          <a className="fs-24" href={`mailto:${t("email")}`}>
            {t("email")}
          </a>
        </div>
      </WideContainer>
    </>
  );
};

export default Contact;
