import useVisible from "rxs/hooks/useVisible";
import { ReactComponent as Dot } from "../images/dot.svg";

const Dots = ({
  amount = 5,
  size = 16,
  // spacing = 16,
  duration = 1,
  className = "",
  ...props
}) => {
  const [visible, ref] = useVisible({ once: true, threshold: 0.2 });

  return (
    <div ref={ref} className={`p-relative ${className}`} {...props}>
      <div
        style={{
          clipPath: `polygon(
        0 0,
        100% 0,
        100% ${!!visible * 100}%,
        0% ${!!visible * 100}%`,
          transition: `all ${duration}s `,
          paddingBottom: 2 * size,
        }}
      >
        {new Array(amount - 1).fill().map((v, i) => (
          <Dot
            key={i}
            className="cl-white"
            width={size}
            height={size}
            style={{
              paddingTop: !!i * size,
              zIndex: -10,
            }}
          />
          // <img key={i} src={dot} alt="dot" width={100} height={100} />
        ))}
      </div>
      <Dot
        width={size}
        height={size}
        className="p-absolute cl-accent"
        style={{
          top: visible ? `calc(100% - ${size}px)` : 0,
          transition: `top ${duration}s `,
          opacity: +visible,
          zIndex: -10,
        }}
      />
    </div>
  );
};

export default Dots;
