import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Avatar, LoremIpsum } from "react-lorem-ipsum";
import WideContainer from "rxs/components/WideContainer";
import Card from "aone/components/Card";
import {
  getGamingHeadline,
  getGamingMediaName,
  getRandomPastDate,
} from "rxs/placeholders";
import { formatDate, getRandomInt } from "rxs/utils";
import { useTranslation } from "react-i18next";

import {
  Background1,
  Background2,
  Background3,
  Background4,
} from "./Background";

import Poster from "images/news/taito-omen.jpg";
import Poster2 from "images/news/taito-launch.jpg";
import useMobile from "rxs/hooks/useMobile";

const news = [
  {
    header: "『OMEN OF SORROW』 arriving to NESiCAxLive2 on December 22, 2022!",
    journalist: "Taito",
    url: "https://www.taito.co.jp/mob/topics/25656",
    date: "12/20/2022",
    background: Poster2,
  },
  {
    header: "『OMEN OF SORROW』 will be distributed on NESiCAxLive2",
    journalist: "Taito",
    url: "https://www.taito.co.jp/mob/topics/25532",
    date: "10/07/2022",
    background: Poster,
  },
  {
    header:
      "Monster fighting game Omen of Sorrow hits new platforms with additional content!",
    journalist: "Eastasiasoft",
    url: "https://www.eastasiasoft.com/news/415/Monster-horror-fighting-game-Omen-of-Sorrow-hits-new-platforms-with-additional-content",
    date: "03/01/2022",
    background: Background1,
  },
  {
    header:
      "From kicks to crossplay: How Omen of Sorrow connects communities with Epic Online Services",
    journalist: "Dev Epic Games",
    url: "https://dev.epicgames.com/en-US/news/from-kicks-to-crossplay-how-omen-of-sorrow-connects-communities-with-epic-online-services",
    date: "02/21/2022",
    background: Background2,
  },
  {
    header:
      "Omen of Sorrow for Xbox One launches September 15; PC version coming to Steam",
    journalist: "Gematsu",
    url: "https://www.gematsu.com/2021/09/omen-of-sorrow-for-xbox-one-launches-september-15-pc-version-coming-to-steam",
    date: "09/10/2021",
    background: Background3,
  },
  {
    header:
      "Omen of Sorrow proves that a small indie developer can make a well-respected, competitive fighting game",
    journalist: "Unreal Engine",
    url: "https://www.unrealengine.com/en-US/developer-interviews/omen-of-sorrow-proves-that-a-small-indie-developer-can-make-a-well-respected-competitive-fighting-game",
    date: "11/29/2018",
    background: Background4,
  },
];

const News = () => {
  const { t } = useTranslation();

  const mobile = useMobile();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className={mobile ? "pt-96" : "pt-224"} />
      <WideContainer>
        <div className="p-32 fs-24 fw-bold  tt-uppercase">{t("newsTitle")}</div>
        <div
          className="grid p-32"
          style={{
            gap: "48px",
            gridTemplateColumns: mobile
              ? "auto"
              : "repeat( auto-fill, minmax(450px, 1fr) )",
          }}
        >
          {news.map((v, i) => (
            <Card key={i} news={v} />
          ))}
          {/* {new Array(getRandomInt(3, 5)).fill().map((v, i) => (
            <Card
              key={i}
              title={getGamingMediaName()}
              date={formatDate(getRandomPastDate())}
            >
              {getGamingHeadline()}
            </Card>
          ))} */}
        </div>
      </WideContainer>
    </>
  );
};

export default News;
