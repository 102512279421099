import { useEffect, useState } from "react";

const useMobile = (mobileWidth = 760) => {
  const [mobile, setMobile] = useState();

  useEffect(() => {
    const handleMobile = () => {
      setMobile(window.innerWidth <= mobileWidth);
    };
    window.addEventListener("resize", handleMobile);
    handleMobile();
    return () => window.removeEventListener("resize", handleMobile);
  }, [mobileWidth]);

  return mobile;
};

export default useMobile;
